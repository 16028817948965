import React, { Component } from 'react';

import { connect }          from 'react-redux';

import Polyline             from '../../../../components/maps/Polyline';
import Marker               from '../../../../components/maps/Marker';
import MultiColorPolyline   from '../../../../components/maps/MultiColorPolyline'

import { getTrips }         from '../../../../redux/routes/details/actions';

import { 
    msToTime,
    dateFormat
}                           from '../../../../lib/utils/Utils';


class CrossynPath extends Component {

    componentDidMount() {
        const { licenseplate } = this.props;
        const { dispatch, startdate, enddate } = this.props;

        const license = licenseplate.replace(/-/g, '').replace(/ /g, '').toUpperCase();
        
        dispatch(getTrips(license, startdate, enddate)).then(({ data, error }) => {
            if (!error) {
                this.interval = setInterval(() => {
                    dispatch(getTrips(license, startdate, enddate));
                }, 120000);
            }
        });
    }
    
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    shouldComponentUpdate(nextProps, nextState) {
        
        const { trips } = this.props;

        if (nextProps.trips && trips && nextProps.trips.length === trips.length) {
            return false;
        }

        return true;
    }

    render() {
        const { trips, behavior } = this.props;

        console.log('behavior', behavior);

        if (!trips) { return null; }

        return (
            trips.filter(({ endTime }) => {
                
                if (!endTime) { return false; }
                return true;

            }).map((trip, index) => {

                const trip_color = '#57004e';
     
                const toFastMargin = 5;

                let normalPath = [];
                let toFastPath = [];
                let toFastPoints = [];

                trip.latlons.forEach(({ lat, lon, datetime, speed=0, speedLimit }) => {
              
                    normalPath.push({ lat: lat, lng: lon, datetime: datetime, speed: speed, speedLimit: speedLimit });

                    const toFast = (speed > (speedLimit+toFastMargin));

                    toFastPath.push({ lat: lat, lng: lon, datetime: datetime, color: (toFast ? '#FF0000' : trip_color), opacity: (toFast? 1 : 0) });

                    if (toFast) {
                        toFastPoints.push({ lat, lon, datetime, speed, speedLimit, toFast: (speed-speedLimit) });
                    }
                    
                });

                return (
                    <React.Fragment key={'l'+index} >
                        
                        <Polyline 
                            path={normalPath} 
                            strokeOpacity={1} 
                            strokeColor={trip_color}
                            strokeWeight={3}
                            icons={[
                                {
                                    icon: {
                                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                        fillColor: trip_color,
                                        fillOpacity: 0.5,
                                        scale: 1.5,
                                    },
                                    offset: "25%"
                                },
                                {
                                    icon: {
                                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                        fillColor: trip_color,
                                        fillOpacity: 0.5,
                                        scale: 1.5,
                                    },
                                    offset: "50%"
                                },
                                {
                                    icon: {
                                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                        fillColor: trip_color,
                                        fillOpacity: 0.5,
                                        scale: 1.5,
                                    },
                                    offset: "75%"
                                }
                            ]}
                        />

                        {
                            behavior
                            &&
                            <>
                                <MultiColorPolyline path={toFastPath} strokeWeight={4} />

                                {
                                    toFastPoints.map(({ lat, lon, datetime, speed, speedLimit, toFast }, index) => {
                                        const size = toFast/10
                                    
                                        return (<Marker
                                            icon={{
                                                scale: (size > 1 ? size : 1)
                                            }}
                                            key={String(lat+lon+index)}
                                            position={ {
                                                latitude: lat,
                                                longitude: lon
                                            }}
                                            hideZoomLevel={14}
                                            infoWindowContent={
                                                <div className='infowindow'>
                                                    Tijd: { dateFormat(new Date(datetime), true) } <br/><br/>
                                                    Snelheid: { speed } km<br/>
                                                    Max snelheid: { speedLimit } km<br/><br/>
                                                    Te snel: { toFast } km<br/>
                                                </div>
                                            }
                                        />);
                                    })
                                }
                            </>
                        }

                

                        {

                        /*
                        <Marker
                            icon={{
                                path: window.google.maps.SymbolPath.CIRCLE,
                                fillColor: 'green',
                                fillOpacity: 0.8,
                                strokeWeight: 0,
                                scale: 4,
                                anchor: {x: 0, y: 0}
                            }}
                            position={ {
                                latitude: path[0].lat,
                                longitude: path[0].lng
                            }}
                            infoWindowContent={
                                <div className='infowindow'>
                                    <b>Start</b><br/><br/>
                                    {/ *'GPS Time:' + path[0].datetime * /} 
                                    Starttijd: { dateFormat(trip_start, true) } <br/>
                                    Eindtijd: { dateFormat(trip_end, true) } <br/>
                                    <br/>
                                    Duur: { trip_duration } <br/>
                                    Afstand: { trip.distance } meter <br/>
                                    <br/>
                                    Id: { trip.tripId }
                                </div>
                            }
                        />
                        <Marker
                            key={'em'+index}
                            icon={{
                                path: window.google.maps.SymbolPath.CIRCLE,
                                fillColor: 'red',
                                fillOpacity: 0.8,
                                strokeWeight: 0,
                                scale: 8,
                                anchor: {x: 0, y: 0}
                            }}
                            position={ {
                                latitude: path[path.length-1].lat,
                                longitude: path[path.length-1].lng
                            }}
                            infoWindowContent={
                                <div className='infowindow'>
                                    <b>Stop</b><br/><br/>
                                    {/ *'GPS Time:' + path[path.length-1].datetime * /}
                                    Starttijd: { dateFormat(trip_start, true) } <br/>
                                    Eindtijd: { dateFormat(trip_end, true) } <br/>
                                    <br/>
                                    Duur: { trip_duration } <br/>
                                    Afstand: { trip.distance } meter<br/>
                                    <br/>
                                    Id: { trip.tripId }
                                </div>
                            }
                        />
                        */
                        }
                    </React.Fragment>
                );

            })
            
        );
    }
}

function mapStateToProps(state, props) {
    
    const { data } = state.routes.current;

	return {
        trips: data.crossyn_trips
	};
}

export default connect(mapStateToProps)(CrossynPath);