import Dashboard from '../../lib/api/Dashboard';


export function vehicleRequest() {
	return {
		type: 'VEHICLE_REQUEST'
	};
}

export function vehicleSuccess(data) {
	return {
		type: 'VEHICLE_SUCCESS',
		data: data,
		lastUpdate: Date.now()
	};
}

export function vehicleError(error) {
	return {
		type: 'VEHICLE_ERROR',
		error: error
	};
}

export function vehiclePositionUpdate(licenseplate, data) {
	return {
		type: 'VEHICLE_POSITION_UPDATE',
		licenseplate: licenseplate,
		data: data
	};
}


/* Async actions */

/*export function getPosition(licenseplate) {

	return function(dispatch, getState) {

		const state = getState();

		if (state.vehicles.loading) {
			return Promise.resolve();
		}
		
        dispatch(vehicleRequest());

		return Dashboard.getVehiclePosition(licenseplate).then(
			data => dispatch(vehicleSuccess(licenseplate, {position: data})),
			error => dispatch(vehicleError(error))
		);
	}
}*/


export function getVehicles() {

	return function(dispatch, getState) {

		const state = getState();

		if (state.vehicles.loading) {
			return Promise.resolve();
		}
		
        dispatch(vehicleRequest());

		return Dashboard.getVehicles({ _limit: 1000 }).then(
			data => dispatch(vehicleSuccess(data)),
			error => dispatch(vehicleError(error))
		);
	}
}