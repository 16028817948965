import React, { Component } from 'react';

import { connect }          from 'react-redux';

import Truck                from '../../../../components/maps/markers/Truck';

import { 
    msToTime,
    dateFormat
}                           from '../../../../lib/utils/Utils';
import { convertLicenseplate } from '../../../../lib/utils/Utils';


class CrossynVehicle extends Component {
    
    render() {
        const { position, type } = this.props;

        //console.log('[CrossynVehicle] render', position);

        if (!position) { return null; }

        return (
            <Truck
                position={{
                    latitude: position.latitude,
                    longitude: position.longitude
                }}
                rotation={(position.rotation ? position.rotation : 0)}
                infoWindowContent={
                    <div className='infowindow'>
                        <b>Locatie</b><br/><br/>
                        Tijd: { dateFormat(new Date(position.timestamp), true) } <br/>
                        Stil: { msToTime(position.duration) }
                    </div>
                }
                type={type}
            />
        );
    }

}

function mapStateToProps(state, props) {

    const vehicle = state.vehicles.data[convertLicenseplate(props.licenseplate)];

	return {
        position: (vehicle ? vehicle.position : null)
	};

}

export default connect(mapStateToProps)(CrossynVehicle);
