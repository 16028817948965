const initialState = {
    
    data: {},

    error: null,
    loading: false,
    lastUpdate: null
};

export default function brancheDetails(state = initialState, action) {

    switch (action.type) {

        case 'BRANCHE_REQUEST':

            return Object.assign({}, state, {
                error: null,
                loading: true
            });

        case 'BRANCHE_SUCCESS':

            var { data, lastUpdate } = action;

            return Object.assign({}, state, {
                data: data,
                error: null,
                loading: false,
                lastUpdate: lastUpdate
            });

        case 'BRANCHE_ERROR':

            var { error } = action;

            return Object.assign({}, state, {
                error: error,
                loading: false
            });

        default:
            return state;
        
    }
    
}
