import React, { Component } from 'react';

import Dashboard            from '../../lib/api/Dashboard';

import Page                 from '../../components/page/Page';
import Activityindicator    from '../../components/activityindicator/Activityindicator';

import { 
    dateFormat
}                           from '../../lib/utils/Utils';

import './Search.css';


class Search extends Component {

    constructor(props) {
        super(props);

        const { search } = props.location;
        const params = new URLSearchParams(search);

        this.state = {
            loading: false,
            type: 'customer',
            query: params.get('customer') || '',
            results: null
        }
    }

    componentDidMount() {
        
        this.loadData();
    }

    loadData = () => {

        const { type, query } = this.state;

        if (!query) { return; }
        
        this.setState({
            loading: true,
            results: null
        });

        Dashboard.search(type, query).then((data) => {
            
            this.setState({
                loading: false,
                results: data
            });

        }).catch((error) => {
            this.setState({
                loading: false,
                results: null
            });
            console.log(error);
        });
    }

    onSubmit = (event) => {

        this.loadData();
        
        event.preventDefault();
    }

    onChange = (event) => {
        
        const { name, value } = event.target;

        if (name === 'type') {
            this.setState({
                type: value,
                results: null
            });
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    renderList(routes) {
        const { type } = this.state;
 
        routes.sort((a,b) => {
            return new Date(b.route.createdAt) - new Date(a.route.createdAt);
        });

        return (
            <ul>
                { 
                    routes.map((result, index) => {
                      
                        let brancheId = result.branche.id;
                        if (type === 'licenseplate' || type === 'customer') {
                            brancheId = result.branche[0]._id;
                        }
                        
                        return (
                            <li key={String('result'+index)}>
                                <a href={'/groothandels/' + brancheId + '/routes/' + result.route.id}>
            
                                    <div className="item-header">
                                        <div style={ {fontSize:18} }>Route {result.route.number}</div>
                                        <div style={ {fontSize:16} }>{ dateFormat(new Date(result.route.createdAt), false)}</div>
                                    </div>
            
                                    { type === 'driver' && <div style={ {color:'#999'} }>{result.driver.name} ({result.driver.number})</div> }
                                    { type === 'customer' && <div style={ {color:'#999'} }>{result.customer.name} {result.customer.second_name} ({result.customer.number})</div> }
                                    { type === 'licenseplate' && <div style={ {color:'#999'} }>{ result?.vehicle[0].licenseplate }</div> }

                                </a>
                            </li>
                        )
                    })
                }
            </ul>
        )
    }

    renderResult() {
        const { results } = this.state;

        if (results === null) { return null; }

        const sectionsObject = results.reduce((branches, route) => {

            const branche = (Array.isArray(route.branche) ? route.branche[0] : route.branche);
            const id = branche._id
   
            if (!branches[id]) {
                branches[id] = {
                    id: id,
                    name: branche.name,
                    routes: []
                }
            }

            branches[id].routes.push(route);

            return branches;
        }, {});

        const branches = Object.values(sectionsObject);

        branches.sort((a,b) => {
            return a.name.localeCompare(b.name);
        });

        results.sort((a,b) => {
            return new Date(b.route.createdAt) - new Date(a.route.createdAt);
        });

        return (
            <div className="card">
                <h2 style={ {margin:0, padding:20} }>{ results.length } routes gevonden</h2>
                <div className="item-list">

                    {
                        branches.map(({ name, routes }) => {
                            return (
                                <>
                                    <div className="branche">{ name }</div>
                                    <ul>
                                        { this.renderList(routes) }
                                    </ul>
                                </>
                            )
                           
                        })
                    }

                </div>
            </div>
        );
    }

	render() {
        const { type, query, loading } = this.state;

        let placeholder = 'Klantnaam of nummer';
        if (type === 'driver') {
            placeholder = 'Chaffeursnaam of nummer';
        } else if (type === 'licenseplate') {
            placeholder = 'Kenteken';
        }

		return (
            <Page>
                <div className="container-centered">
                    
                    <div className="card">
                        <h2 style={ {margin:0, padding:20} }>Zoeken</h2>
                        <form className="search-form" onSubmit={this.onSubmit}>
                            <div className="field" style={ {alignItems:'center'} }>
                                <input type="radio" name="type" id="customer" value="customer" onChange={this.onChange} defaultChecked />
                                <label htmlFor="customer">Klant</label>
                            </div>
                            <div className="field" style={ {alignItems:'center'} }>
                                <input type="radio" name="type" id="driver" value="driver" onChange={this.onChange} />
                                <label htmlFor="driver">Chauffeur</label>
                            </div>
                            <div className="field" style={ {alignItems:'center'} }>
                                <input type="radio" name="type" id="licenseplate" value="licenseplate" onChange={this.onChange} />
                                <label htmlFor="licenseplate">Kenteken</label>
                            </div>
                            <div className="field">
                                <input type="text" name="query" value={query} placeholder={placeholder} minLength="3" onChange={this.onChange} required />
                                <button type="submit" disabled={loading}>Zoeken</button>
                            </div>
                        </form> 
                    </div>

                    { loading && <div className="loading"><Activityindicator /></div> }

                    { this.renderResult() }

                </div>
            </Page>
		);
	}
}

export default Search;