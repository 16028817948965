import React, { Component } from 'react';

import {
    Link
}                           from 'react-router-dom';

import { connect }          from 'react-redux';

import './Item.css';

class Item extends Component {
    
    render() {

        const { branche: { id, name, number, color}, url } = this.props;

        return (
            <Link to={url + '/' + id} >
                <div className="branche_link">
                    <div>{ name } ({ number })</div>
                    <div className="branche_color" style={{backgroundColor: color}} />
                </div>
            </Link>
        );
    }
}

Item.defaultProps = {
    id: null,
    url: ''
};

function mapStateToProps(state, props) {
    
    const { data } = state.branches.all;

	return {
        branche: data[props.id] ? data[props.id] : {}
	};
}

export default connect(mapStateToProps)(Item);
