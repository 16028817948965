import React, { Component } from 'react';

import _                    from 'lodash';

import {
    msToTime,
}                           from '../../../../lib/utils/Utils';

import './StopList.css';

class StopList extends Component {

    shouldComponentUpdate(nextProps, nextState) {

        if (!_.isEqual(nextProps.stops, this.props.stops)) {
            return true;
        }

        return true;
    }

    onClick = (latitude, longitude) => {
        const { getMap } = this.props;
      
        const map = getMap();
        if (map) {
            map.center(latitude, longitude, 17);
        }

    }

    render() {
        const { stops=[], activities } = this.props;

        if (stops.length === 0) { return null; }

        const max_duration = 30 * 60 * 1000;

        const activities_obj = activities.reduce((activities, activity) => {
            activities[activity.id] = activity;
            return activities;
        }, {});

        return (
            <div className="stop-list" style={{padding: 20}}>
                <table style={{width: '100%'}}>
                    <thead>
                        <tr>
                            <th width="5px"></th>
                            <th align="left">Aankomst</th>
                            <th align="left" width="60px">Duur</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        stops.map((stop, index) => {
                            const { latitude, longitude, timestamp, duration, activities } = stop;

                            if (duration < (1000*60) && activities.length === 0) { 
                                return null;
                            }

                            return (
                                <React.Fragment key={'stp'+index}>
                                    <tr  className={(duration > max_duration ? 'tolong' : '')} onClick={this.onClick.bind(this, latitude, longitude)}>
                                        <td></td>
                                        <td>{new Date(timestamp).toLocaleTimeString()}</td>
                                        <td>{ msToTime(duration) }</td>
                                    </tr>
                                    {
                                        activities.length > 0
                                        &&
                                        <React.Fragment>
                                            {
                                                activities.map((activity, index) => {
                                                    
                                                    if (activity.type === 'activity') {
                                                        const { id, distance } = activity;
                                                        if (!activities_obj[id]) { console.log('Not found:', id);  return null; }

                                                        const { customer } = activities_obj[id];
                                                        
                                                        return (
                                                            <tr key={'tra'+index} className={'matched' + (duration > max_duration ? ' tolong' : '')} onClick={this.onClick.bind(this, latitude, longitude)}>
                                                                <td></td>
                                                                <td>{ customer.name }</td>
                                                                <td>{ Math.round(distance) } M.</td>
                                                            </tr>
                                                        )
                                                    } else {
                                                        const { id, distance, poi } = activity;
                                     
                                                        return (
                                                            <tr key={'tra'+index} className={'matched' + (duration > max_duration ? ' tolong' : '')} onClick={this.onClick.bind(this, latitude, longitude)}>
                                                                <td></td>
                                                                <td>{ poi.name }</td>
                                                                <td>{ Math.round(distance) } M.</td>
                                                            </tr>
                                                        )
                                                    }
                                                    
                                                

                                                })
                                            }
                                            <tr>
                                                <td colSpan="3" ></td>
                                            </tr>
                                        </React.Fragment>
                                    }
                                
                                </React.Fragment>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    
    }
}

export default StopList;