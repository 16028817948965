import { bearing }          from '../../lib/crossyn/GPSUtils';


const initialState = {
    
    data: {},

    error: null,
    loading: false,
    lastUpdate: null
};

export default function vehicles(state = initialState, action) {

    switch (action.type) {

        case 'VEHICLE_REQUEST':

            return Object.assign({}, state, {
                error: null,
                loading: true
            });

        case 'VEHICLE_SUCCESS':

            var { data, lastUpdate } = action;
            var vehicles = {};
            
            data.forEach((vehicle) => {
                vehicles[vehicle.licenseplate] = vehicle;
            });

            return Object.assign({}, state, {
                data: vehicles,
                error: null,
                loading: false,
                lastUpdate: lastUpdate
            });

        case 'VEHICLE_ERROR':

            var { error } = action;

            return Object.assign({}, state, {
                error: error,
                loading: false
            });

        case 'VEHICLE_POSITION_UPDATE':
            
            var { licenseplate, data } = action;
            /*var vehicle = state.data[licenseplate];
            
            if (vehicle) {
                
                var last_position = vehicle.position;

                if (last_position.latitude === data.latitude && last_position.longitude === data.longitude) {
                    
                    var time_new = new Date(data.timestamp).getTime();
                    var time_last = new Date(last_position.timestamp).getTime();
                    var duration = (time_new - time_last) / 1000;
                    
                    data.duration = last_position.duration + duration;
                    data.rotation = last_position.rotation;
                } else {
                    data.duration = 0;
                    data.rotation = bearing(last_position.latitude, last_position.longitude, data.latitude, data.longitude);
                }

            } else {
                data.duration = 0;
                data.rotation = 0;
            }*/

            var vehicles = {
                ...state.data,
                [licenseplate]: {
                    ...state.data[licenseplate],
                    position: data
                }
            };

            return Object.assign({}, state, {
                data: vehicles
            });

        default:
            return state;
        
    }
    
}
