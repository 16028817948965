import WebService           from '../webservice/WebService';
import Subscriptions        from '../subscriptions/Subscriptions';

class Dashboard {

    constructor() {

        const url = process.env.REACT_APP_DASHBOARD_URL;
        const path = process.env.REACT_APP_DASHBOARD_API_PATH;

        this.webservice = new WebService({
            url: url + (path ? path : '')
        });

        this.subscriptions = new Subscriptions({
            url: url,
            path: (path ? path : '')
        });

    }

    setToken = (token) => {
        this.webservice.addHeader('Authorization', 'Bearer ' + token);
        this.subscriptions.open(token);
    }

    removeToken = () => {
        this.webservice.removeHeader('Authorization');
        this.subscriptions.close();
    }

    subscribe = (...args) => {
        return this.subscriptions.subscribe(...args);
    }

    login = (identifier, password) => {
        return this.webservice.post('/auth/local', {identifier: identifier, password: password});
    }

    getBranches = () => {
        return this.webservice.get('/branches?dashboard=true');
    }

    getBranche = (id) => {
        return this.webservice.get('/branches/' + id);
    }

    getRoutes = (params) => {
        return this.webservice.get('/routes', params);
    }

    getRoute = (id) => {
        return this.webservice.get('/routes/' + id);
    }

    getTrips = (licenseplate, params) => {
        return this.webservice.get('/vehicles/' + licenseplate + '/trips', params);
    }

    getVehicles = (params) => {
        return this.webservice.get('/vehicles', params);
    }

    getVehiclePosition = (licenseplate) => {
        return this.webservice.get('/vehicles/' + licenseplate + '/position');
    }

    getPois = () => {
        return this.webservice.get('/pois');
    }

    search = (type, query) => {

        if (type === 'driver') {
            return this.webservice.get('/routes/search', {q: query});
        } else if (type === 'customer') {
            return this.webservice.get('/activities/search', {q: query});
        } else if (type === 'licenseplate') {
            return this.webservice.get('/vehicles/search', {q: query});
        }
 
    }

    getBehaviour = (driverId, driverHanosStoreNumber, licensePlate, startdate ) => {
        return this.webservice.post('/hanos/getBehavior', driverId, driverHanosStoreNumber, licensePlate, startdate );
    }
}

export default new Dashboard();