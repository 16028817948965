const socket    = require('socket.io-client');

class Subscriptions {
    
    constructor({ url, path, port, headers={} }) {

        this.headers = headers;

        this.socket = socket(url + (port ? ':' + port : ''), {
            path: path + '/socket.io/',
            query: this.getQuery(),
            autoConnect: false
        });

        this.socket.on('connect', this.connected);
        this.socket.on('disconnect', this.disconnect);
        this.socket.on('error', this.error);

        this.socket.on('subscribed', this.subscribed);
        this.socket.on('action', this.action);

        this.subscriptions = {}

    }

    open = (token='') => {

        if (token !== '') {
            this.headers.token = token;
            this.socket.io.opts.query = this.getQuery();
        }

        this.socket.open();
    }

    close = () => {
        this.socket.close();

        if (this.headers.token) {
            delete this.headers.token;
        }
    }

    connected = () => {
        console.log('[Subscriptions] Connected');
        this.check();
    }

    disconnect = (response) => {
        console.log('[Subscriptions] Disconnect', response);
    }

    error = (response) => {
        console.log('[Subscriptions] Error: ', response);
    }

    subscribe = (...args) => {

        const path = args[0];

        let callback = () => {};
        let filters = {}

        if (typeof args[1] === "function") {
            callback = args[1];
        } else {
            filters = args[1];
            callback = args[2];
        }


        if (!this.subscriptions.hasOwnProperty(path)) {
            this.subscriptions[path] = {};
        }

        let cbid = Math.ceil(Math.random()*10000000) + Object.keys(this.subscriptions[path]).length;

        this.subscriptions[path][cbid] = {
            id: cbid,
            filters: filters,
            callback: callback
        };

        if (this.socket.connected) {

            this.socket.emit('subscribe', {
                path: path,
                filters: filters,
                id: cbid
            });

        }

        return {
            remove: () => {
                
                delete this.subscriptions[path][cbid];

                if (this.socket.connected) {
                    this.socket.emit('unsubscribe', {
                        path: path,
                        id: cbid
                    });
                }
            }
        }

    }

    subscribed = (response)  => {
        //const { module, hash } = response;
    }

    action = (response)  => {
        const { path, data, ids } = response;

        //console.log('[Subscriptions] Action: ' + path);

        if (this.subscriptions.hasOwnProperty(path)) {
            const subscription = this.subscriptions[path];

            ids.forEach((id) => {
                if (subscription.hasOwnProperty(id)) {
                    subscription[id].callback(data);
                }
            });
        }
    }

    check = () => {

        Object.keys(this.subscriptions).forEach((path) => {

            Object.keys(this.subscriptions[path]).forEach((cbid) => {
                const { filters } = this.subscriptions[path][cbid];
                this.socket.emit('subscribe', {
                    path: path,
                    filters: filters,
                    id: cbid
                });
            }); 

        }); 

    }

    getQuery = () => {

        let query_array = [];
        for (let key in this.headers) {
            query_array.push(encodeURIComponent(key) + "=" + encodeURIComponent(this.headers[key]));
        }

        return query_array.join("&");
    }

}

export default Subscriptions;