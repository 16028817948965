import React, { Component } from 'react';

import _                    from 'lodash';

import {
    msToTime
}                           from '../../../../lib/utils/Utils';

import Marker               from '../../../../components/maps/Marker';
import Circle               from '../../../../components/maps/Circle';
import Line                 from '../../../../components/maps/Line';

class Stops extends Component {

    shouldComponentUpdate(nextProps, nextState) {

        if (!_.isEqual(nextProps.stops, this.props.stops)) {
            return true;
        }

        return false;
    }


    render() {
        const { stops=[], activities } = this.props;

        const stop_radius = 50;

        const activities_obj = activities.reduce((activities, activity) => {
            activities[activity.id] = activity;
            return activities;
        }, {});

        return (
            <React.Fragment>
                {
                    stops.map((stop, index) => {
                        const { latitude, longitude, timestamp, duration, activities } = stop;

                        const geoMinDuration = 15 * 60 * 1000;
                        if (activities.length === 0 && duration < geoMinDuration) {
                            return null;
                        }

                        return (
                            <React.Fragment key={'s'+index}>
            
                                <Marker
                                    key={'d'+index}
                                    icon={{
                                        url: '/images/stop-icon.png',
                                        scaledSize: new window.google.maps.Size(16, 16),
                                        anchor: {x: 8, y: 8}
                                    }}
                                    position={ {
                                        latitude: latitude,
                                        longitude: longitude
                                    }}
                                    zIndex={9999}
                                    hideZoomLevel={11}
                                    infoWindowContent={
                                        <div className='infowindow'>
                                            <span style={{fontWeight:'bold'}}>Aangekomen:</span> { new Date(timestamp).toLocaleTimeString() } <br/>
                                            <span style={{fontWeight:'bold'}}>Stilstand:</span> { msToTime(duration) }<br/>
                                            <br/>
                                            <span style={{fontWeight:'bold'}}>Latitude:</span> { latitude } <br/>
                                            <span style={{fontWeight:'bold'}}>Longitude:</span> { longitude } <br/><br/>
                                            <span style={{fontWeight:'bold'}}>Hoort bij:</span> <br/>
                                            {
                                                activities.map(({ id, distance }, index) => {

                                                    if (!activities_obj[id]) { return null; }

                                                    const { customer } = activities_obj[id];

                                                    if (customer === null) { return null; }
                           
                                                    return (<div key={'cn'+index}> { customer.name } { Math.round(distance) } M.<br/></div> )
                                                })
                                            }
                                        </div>
                                    }
                                />

                                {
                                    activities.map((activity, index) => {
                                        const { type, poi, id } = activity;

                                        if (type === 'poi') {
                                            const { category, coordinates: { coordinates }, name, street_number, postalcode, city } = poi;

                                            return (
                                                <Marker
                                                    key={'poi'+index}
                                                    icon={{
                                                        url: '/images/shell-icon.png',
                                                        scaledSize: new window.google.maps.Size(16, 16),
                                                        anchor: {x: 8, y: 8}
                                                    }}
                                                    position={ {
                                                        latitude: coordinates[1],
                                                        longitude: coordinates[0]
                                                    }}
                                                    zIndex={9999}
                                                    hideZoomLevel={11}
                                                    infoWindowContent={
                                                        <div className='infowindow'>
                                                            <span className='name'>{ name }</span><br />
                                                            { street_number }<br />
                                                            { postalcode } { city }          
                                                        </div>
                                                    }
                                                />
                                            );
                                        } else {
                                            return (
                                                <Line  
                                                    key={'c'+activity.id}  
                                                    strokeWeight={2}
                                                    strokeOpacity={0.3}
                                                    path={[
                                                        { lat: latitude, lng: longitude },
                                                        { lat: activity.latitude, lng: activity.longitude },
                                                    ]}
                                                />
                                            );
                                        }
                                       
                                    })
                                }

                            </React.Fragment>
                        )
                    })
                }
        </React.Fragment>
        );

    
    }
}

export default Stops;