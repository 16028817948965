import React, { PureComponent } from 'react';

import { MapContext }       from './MapProvider';

class MultiColorPolyline extends PureComponent {

    constructor(props, context) {
        super(props, context);   
        
        this.polyline = null;

        this.drawLines();
        
    }

    componentDidUpdate() {
        this.drawLines();
    }

    componentWillUnmount() {
        //this.polyline.setMap(null);
    }

    drawLines = () => {
        const { path, strokeWeight, icons=null } = this.props;

        for (let i = 0; i < path.length; i++) {
            const point = path[i];
            const nextPoint = path[i+1];

            if (nextPoint) {
                const line = new window.google.maps.Polyline({
                    path: [point, nextPoint],
                    geodesic: true,
                    strokeColor: point.color,
                    strokeOpacity: point.opacity,
                    strokeWeight: strokeWeight,
                    zIndex: 999
                    //icons: icons
                });
    
                line.setMap(this.context.map);
            }

       
        }

    }

    render() {
        return null;
    }

}

MultiColorPolyline.contextType = MapContext;

MultiColorPolyline.defaultProps = {
    path: [],
    strokeColor: '#57004e',
    strokeOpacity: 1,
    strokeWeight: 2
};

export default MultiColorPolyline;
