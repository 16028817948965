import React, { Component } from 'react';

import { connect }          from 'react-redux';

import {
    Link
}                           from 'react-router-dom';

import {
    mdiCheck,
    mdiTruck,
    mdiMapMarker,
    mdiMapMarkerRemove
}                           from '@mdi/js';

import Icon                 from '@mdi/react';

import { updateActivity }   from '../../../../redux/routes/list/actions';
import { 
    getSortedActivities 
}                           from '../../../../redux/routes/list/selectors';

import {
    convertLicenseplate
}                           from '../../../../lib/utils/Utils';
import Dashboard            from '../../../../lib/api/Dashboard';



import './RouteInfo.css';


class Item extends Component {
    
    componentDidMount() {
        
        const { data, dispatch } = this.props;
        
        this.dashboard_activity_updated = Dashboard.subscribe('/activity/updated', {route_id: data.route_id}, (activity) => {
            dispatch(updateActivity(data.id, activity));
        });
    }

    componentWillUnmount() {

        if (this.dashboard_activity_updated) {
            this.dashboard_activity_updated.remove();
        }
    }

    renderActivity() {

        const { data, activities, vehicles } = this.props;

        if (data.status === 'done') {

            return (
                <div className="activity-container">
                    <Icon path={mdiCheck} size={'1.4rem'} style={ {marginRight: 5} } />
                    <span>Einde van de rit</span>
                </div>
            );

        } else if (data.status === 'todock_driving' || data.status === 'todock_arrived') {
            
            return (
                <div className="activity-container">
                    <Icon path={(data.status === 'todock_driving' ? mdiTruck : mdiMapMarker)} size={'1.4rem'} style={ {marginRight: 5} } />
                    <span>Dock</span>
                </div>
            );

        } else {

            const activity = activities.find((activity) => {
                return activity.status.startsWith('driving') || activity.status.startsWith('arrived');
            });
    
            if (!activity) { return null; }
    
            return (
                <div className="activity-container">
                    <Icon path={(activity.status.startsWith('driving') ? mdiTruck : mdiMapMarker)} size={'1.4rem'} style={ {marginRight: 5} } />
                    <span>{(activity.customer ? activity.customer.name : '')}</span>
                </div>
            );
        }
    }

    renderProgress() {
        const { activities } = this.props;

        return (
            <div className="progress-container">
                { 
                    activities.map(({id, status, active=true}, index) => {
                        return <div key={index + id} className={'progress-item progress-'+status+' ' + (active ? 'active_true' : 'active_false')}></div>
                    }) 
                }
            </div>
        );
    }

    render() {
        const { data, url, vehicles } = this.props;

        return (
            <Link to={url}>
                <div className="item-header" style={ {fontSize:'1.8rem'} }><span>Route { data.number }</span> <span className="driver">{ data.driver_name } { (!(data.extra_data ? vehicles[convertLicenseplate(data.extra_data.licenseplate)] : null)) && <Icon path={mdiMapMarkerRemove} size={'1.3rem'} color={'#c60000'}/> }</span></div>
                { this.renderProgress() }
                { this.renderActivity() }
            </Link>
        );
    }
}

Item.defaultProps = {
    url: '#',
    route_id: null
};

function mapStateToProps(state, props) {

    const { data } = state.routes.all;
    const { data: vehicles } = state.vehicles;

    let activities = [];
    if (data[props.route_id]) {
        activities = data[props.route_id].activities;
    }
    
	return {
        data: data[props.route_id] ? data[props.route_id] : {},
        activities: getSortedActivities(activities),
        vehicles: vehicles
        
	};
}

export default connect(mapStateToProps)(Item);
