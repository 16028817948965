import React, { Component }             from 'react';

import { Provider }                     from 'react-redux';
import { PersistGate }                  from 'redux-persist/integration/react';

import {
    BrowserRouter,
    Switch,
    Route,
    Redirect
}                                       from 'react-router-dom';

import jwtDecode                        from 'jwt-decode';

import AuthRoute			            from './router/AuthRoute';

import Branches                         from './modules/branches/Branches';
import Login                            from './modules/authentication/Login';   
import notFound                         from './modules/notfound/notFound';  
import Search                           from './modules/search/Search';     

import Dashboard                        from './lib/api/Dashboard';

import configureStore                   from './redux/store';
import { logout }                       from './redux/user/actions';
import {
    getVehicles,
    vehiclePositionUpdate
}                                       from './redux/vehicles/actions';

import GoogleAnalytics                  from './components/trackers/GoogleAnalytics';

const { store, persistor } = configureStore();

class App extends Component {

    getAllPositions = () => {
        
        return store.dispatch(getVehicles()).then(({ data, error }) => {
            if (!error) {
                this.dashboard_position_updated = Dashboard.subscribe('/position/updated', (data) => {
                
                    //console.log('getAllPositions', data.length);
                    
                    data.forEach(({ licenseplate, position }) => {
                        if (position) {
                            store.dispatch(vehiclePositionUpdate(licenseplate, position));
                        } 
                    });
                });
            }
        });
    }

    onBeforeLift = async () => {
        const { user: { authenticated, jwt } } = store.getState();
        
        if (authenticated) {
            Dashboard.setToken(jwt);
        }

        if (jwt) {
            const { iat, exp } = jwtDecode(jwt);
            const expires = (exp * 1000) - Date.now();
            
            if (expires <= 0) {
                Dashboard.removeToken();
                store.dispatch(logout());
            }

            await this.getAllPositions();
        }
    }

    render() {
        return (
            <Provider store={store}>
                <PersistGate 
                    persistor={persistor}
                    onBeforeLift={this.onBeforeLift}
                >
                    <BrowserRouter>
                        <Switch>
                            <Redirect from="/" to="/groothandels" exact />
                            <Route path="/login" exact component={Login} />
                            <AuthRoute path="/groothandels" component={Branches} />
                            <AuthRoute path="/zoeken" component={Search} />
                            <Route path="*" component={notFound} />
                        </Switch>
                        <GoogleAnalytics />
                    </BrowserRouter>
                    
                </PersistGate>
            </Provider>
        );
    }
}

export default App;
